import React, { useContext } from 'react';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { TextField, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_FORMACION } from 'constants/constants';

const classes = {
   labelCodigo: {
      mr: 1,
      fontWeight: 'bold'
   }
}

const SelectProductos = ({ onChange }) => {

   const { cursos, loading } = useSelector(state => state.cursos)

   const { state } = useContext(CotizacionContext)

   const onSelectProducto = (_, productoAutoComplete) => {
      const producto = getOptions().find(p => p.id === productoAutoComplete.id);
      onChange(producto);
   }

   const getOptions = () => {
      const cursosOptions = cursos.map(c => ({ id: c.id, label: `${c.codigo ? c.codigo : ''}:-: ${c.nombre}`, tipo: 'curso' }))
      const productosOptions = state.productos.map(p => ({ id: p.id, label: p.nombre, tipo: 'producto' }))

      if (state.json.tipo === TIPO_SOLICITUD_FORMACION) return [...cursosOptions];


      return [...productosOptions]
   }

   return (
      <Autocomplete
         disablePortal
         onChange={onSelectProducto}
         id="combo-box-demo"
         options={getOptions()}
         renderInput={(params) => <TextField {...params} label="Concepto" />}
         renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            const info = option.label.split(':-:')

            return (
               <Box
                  key={key}
                  component="li"
                  {...optionProps}
               >
                  <Typography >
                     {
                        (info[0] && info[0] !== '') &&
                        <Typography component={'span'} sx={classes.labelCodigo}>
                           {info[0]}:
                        </Typography>
                     }

                     {info[1]}
                  </Typography>
               </Box>
            );
         }}
      />
   );
};

export default SelectProductos;
